'use strict';
import Swiper, { Navigation, Autoplay, } from 'swiper';

var offers;

document.addEventListener('DOMContentLoaded', function() {

    new Swiper('.secondary-navbar .nav-items', {
        slidesPerView: 'auto',
        width: 'auto',
    });

    new Swiper('.categories.swiper', {
        slidesPerView: 'auto',
        width: 'auto',
        modules: [ Autoplay ],
        autoplay: true
    });
    
    offers = document.querySelectorAll('.offers');

    offers.forEach(el => {
        //if ( el.querySelector('.is-multiline') ) return;
        el.style.height = el.clientHeight + 'px';
    })

    setTimeout( () => window.dispatchEvent(new Event('resize')), 500);

    adjustCardHeightsMobile(offers)
});

window.addEventListener('resize', () => {
    
    offers && offers.forEach(el => {
        //if ( el.querySelector('.is-multiline') ) return;

        el.style.height = 'auto';
        el.style.height = window.innerWidth > 767 ? el.offsetHeight + 'px' : 'auto';
    })

    adjustCardHeightsMobile(offers)

})

function adjustCardHeightsMobile(offers)
{
    if ( window.innerWidth <= 767 ) {
        let lastHeight = 0;
        let c = 0;
        for ( let el of document.querySelectorAll('.card.offer') ) {
            if (el.clientHeight > lastHeight) lastHeight = el.clientHeight;
        }

        document.querySelectorAll('.card.offer')
        .forEach(el => {
            el.style.height = lastHeight + 'px';
            console.log(el.style.height);
        })
    } else {
        document.querySelectorAll('.card.offer')
        .forEach(el => {
            //if ( c % 2 == 0 && c != 0) c = 0;
            el.style.height = '100%';
        })
    }
}